<template>
  <div
    ref="chartdiv"
    style="width: 100%; height: 500px;"
  />
</template>

<script>

import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themesAnimated)

export default {
  name: 'DonutChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    valueField: {
      type: String,
      required: true,
    },
    categoryField: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart)

    chart.data = this.data

    const title = chart.titles.create()
    title.text = this.title
    chart.innerRadius = am4core.percent(50)
    const series = chart.series.push(new am4charts.PieSeries())
    series.dataFields.value = this.valueField
    series.dataFields.category = this.categoryField
    series.slices.template.stroke = am4core.color('#fff')
    series.slices.template.strokeOpacity = 1
    series.slices.template.strokeWidth = 2

    // This creates initial animation
    series.hiddenState.properties.opacity = 1
    series.hiddenState.properties.endAngle = -90
    series.hiddenState.properties.startAngle = -90
    chart.exporting.menu = new am4core.ExportMenu()

    this.chart = chart
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<style scoped>

</style>
