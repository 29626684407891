<template>
  <div
    ref="chartdiv"
    style="width: 100%; height: 500px;"
  />
</template>

<script>

import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themesAnimated)
export default {
  name: 'ColumnChart3D',
  props: {
    data: {
      type: Array,
      required: true,
    },
    categoryField: {
      type: String,
      required: true,
    },
    valueField: {
      type: String,
      required: true,
    },
    seriesName: {
      type: String,
      required: true,
    },
    xAxisTitle: {
      type: String,
      default: '',
    },
    yAxisTitle: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart3D)

    chart.data = this.data

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = this.categoryField
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.labels.template.rotation = 270
    categoryAxis.renderer.labels.template.hideOversized = false
    categoryAxis.renderer.minGridDistance = 20
    categoryAxis.title.text = this.xAxisTitle

    categoryAxis.renderer.labels.template.horizontalCenter = 'right'
    categoryAxis.renderer.labels.template.verticalCenter = 'middle'
    categoryAxis.tooltip.label.rotation = 270
    categoryAxis.tooltip.label.horizontalCenter = 'right'
    categoryAxis.tooltip.label.verticalCenter = 'middle'

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.title.text = this.yAxisTitle
    valueAxis.title.fontWeight = 'bold'

    const series = chart.series.push(new am4charts.ColumnSeries3D())
    series.dataFields.valueY = this.valueField
    series.dataFields.categoryX = this.categoryField
    series.name = this.seriesName
    series.tooltipText = '{name}: [bold]{valueY}[/]'
    series.columns.template.fillOpacity = 0.8
    // series.columns.template.propertyFields.fill = 'color'
    const columnTemplate = series.columns.template
    columnTemplate.strokeWidth = 2
    columnTemplate.strokeOpacity = 1
    columnTemplate.stroke = am4core.color('#FFFFFF')

    columnTemplate.adapter.add('fill', (fill, target) => chart.colors.getIndex(target.dataItem.index))

    columnTemplate.adapter.add('stroke', (stroke, target) => chart.colors.getIndex(target.dataItem.index))
    chart.exporting.menu = new am4core.ExportMenu()
    this.chart = chart
    chart.cursor = new am4charts.XYCursor()
    chart.cursor.lineX.strokeOpacity = 0
    chart.cursor.lineY.strokeOpacity = 0
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<style scoped>

</style>
