<template>
  <div
    ref="chartdiv"
    style="width: 100%; height: 500px;"
  />
</template>

<script>

import * as am4core from '@amcharts/amcharts4/core'
import * as am4pluginsForceDirected from '@amcharts/amcharts4/plugins/forceDirected'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themesAnimated)

export default {
  name: 'ForceDirectedTree',
  props: {
    data: {
      type: Array,
      required: true,
    },
    nodeTitle: {
      type: String,
      required: true,
    },
    linkTitle: {
      type: String,
      required: true,
    },
    linkProperty: {
      type: String,
      required: true,
    },
    nodeWidth: {
      type: Number,
      default: 40,
    },
    nodeHeight: {
      type: Number,
      default: 40,
    },
  },
  mounted() {
    const chart = am4core.create(this.$refs.chartdiv, am4pluginsForceDirected.ForceDirectedTree)
    const networkSeries = chart.series.push(new am4pluginsForceDirected.ForceDirectedSeries())
    chart.data = this.data

    networkSeries.dataFields.value = 'value'
    networkSeries.dataFields.name = 'name'
    networkSeries.dataFields.children = 'children'
    networkSeries.nodes.template.tooltipText = '{name}: {value}'
    networkSeries.nodes.template.fillOpacity = 1

    networkSeries.nodes.template.label.text = `{${this.nodeTitle}}`
    networkSeries.fontSize = 10

    networkSeries.links.template.strokeWidth = 1

    const hoverState = networkSeries.links.template.states.create('hover')
    hoverState.properties.strokeWidth = 3
    hoverState.properties.strokeOpacity = 1

    networkSeries.nodes.template.events.on('over', event => {
      event.target.dataItem.childLinks.each(link => {
        // eslint-disable-next-line no-param-reassign
        link.isHover = true
      })
      if (event.target.dataItem.parentLink) {
        // eslint-disable-next-line no-param-reassign
        event.target.dataItem.parentLink.isHover = true
      }
    })

    // networkSeries.nodes.template.circle.radius = [200, 200]
    networkSeries.nodes.template.events.on('out', event => {
      event.target.dataItem.childLinks.each(link => {
        // eslint-disable-next-line no-param-reassign
        link.isHover = false
      })
      if (event.target.dataItem.parentLink) {
        // eslint-disable-next-line no-param-reassign
        event.target.dataItem.parentLink.isHover = false
      }
    })
    chart.maxLevels = 2
    chart.centerStrength = 0.6
    chart.manyBodyStrength = -20
    chart.linkWithStrength = 0.5

    chart.exporting.menu = new am4core.ExportMenu()
    this.chart = chart
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<style scoped>

</style>
