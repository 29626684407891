<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <vue-good-table-vue
            :columns="columns"
            :rows="rows"
            title="Le chiffre d'affaires réalisé par chaque commercial"
          />
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Comparaison des chiffres d'affaires des différents commerciaux">
          <ColumnChart3D
            :data="salesData"
            :category-field="'commercial'"
            :value-field="['chiffreAffaires']"
            :x-axis-title="'Commerciaux'"
            :y-axis-title="'Chiffre d\'affaires (en millions de FCFA)'"
            series-name="test"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card title="Visualisation des relations hiérarchiques entre les différents commerciaux et les clients">
          <force-directed-tree
            :data="dataForceDirectedTree1"
            node-title="name"
            link-title="count"
            link-property="clients"
            :node-width="Number('500')"
            :node-height="Number('500')"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <DonutChart
            :data="chartData"
            title="La répartition des ventes par catégorie de produits"
            value-field="value"
            category-field="category"
          />
        </b-card>
      </b-col>
      <b-col>
        <b-card title="Représentation graphique de l'évolution des ventes">
          <line-with-changing-color
            :data="salesData2"
            value-field="sales"
            date-field="date"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-card title="Représentation de la durée de rotation moyenne de différents produits en stock">
          <RotationChart
            :data="stockData"
          />
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Représentation graphiquement des ventes par mois et par commercial, avec des colonnes empilées pour chaque mois">
          <stacked-column-chart
            :data="salesData3"
            category-field="month"
            :series="salesSeries"
            x-axis-title="Mois"
            y-axis-title="Ventes"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import VueGoodTableVue from '@/views/m-components/listes/VueGoodTableVue.vue'
import { BCard, BCol, BRow } from 'bootstrap-vue'
import ColumnChart3D from '@/views/m-components/charts/ColumnChart3D.vue'
import DonutChart from '@/views/m-components/charts/DonutChart.vue'
import ForceDirectedTree from '@/views/m-components/charts/ForceDirectedTree.vue'
import LineWithChangingColor from '@/views/m-components/charts/LineWithChangingColor.vue'
import RotationChart from '@/views/m-components/charts/RotationChart.vue'
import StackedColumnChart from '@/views/m-components/charts/StackedColumnChart.vue'

export default {
  name: 'MainVue',
  components: {
    StackedColumnChart,
    RotationChart,
    LineWithChangingColor,
    ForceDirectedTree,
    DonutChart,
    ColumnChart3D,
    BCard,
    BRow,
    BCol,
    VueGoodTableVue,
  },
  data() {
    return {
      columns: [
        {
          label: 'Nom du commercial',
          field: 'nom',
        },
        {
          label: 'Chiffre d\'affaires',
          field: 'chiffreAffaires',
        },
      ],
      rows: [
        {
          nom: 'Adama Ouedraogo',
          chiffreAffaires: '100 000 000',
        },
        {
          nom: 'Aminata Diallo',
          chiffreAffaires: '150 000 000',
        },
        {
          nom: 'Bassirou Traoreo',
          chiffreAffaires: '80 000 000',
        },
        {
          nom: 'Mariam Kabore',
          chiffreAffaires: '120 000 000',
        },
        {
          nom: 'Ousmane Kone',
          chiffreAffaires: '20 000 000',
        },
        {
          nom: 'Salifou Ouattara',
          chiffreAffaires: '70 000 000',
        },
      ],
      salesData: [
        { commercial: 'Adama Ouedraogo', chiffreAffaires: 15.8 },
        { commercial: 'Aminata Diallo', chiffreAffaires: 11.2 },
        { commercial: 'Bassirou Traore', chiffreAffaires: 19.5 },
        { commercial: 'Mariam Kabore', chiffreAffaires: 8.9 },
        { commercial: 'Ousmane Kone', chiffreAffaires: 13.7 },
        { commercial: 'Salifou Ouattara', chiffreAffaires: 22.1 },
      ],
      chartData: [
        { category: 'Jus', value: 500 },
        { category: 'Boissons gazeuses', value: 800 },
        { category: 'Eau minérale', value: 300 },
      ],
      dataForceDirectedTree1: [
        {
          name: 'AFRIDIA',
          children: [
            {
              name: 'Amadou Diallo',
              value: 25000,
              children: [
                {
                  name: 'Sadio Traore',
                  value: 10000,
                  children: [
                    {
                      name: 'Aminata Kone',
                      value: 5000,
                      children: [],
                    },
                    {
                      name: 'Ousmane Sow',
                      value: 5000,
                      children: [],
                    },
                  ],
                },
                {
                  name: 'Boureima Ouedraogo',
                  value: 15000,
                  children: [
                    {
                      name: 'Adama Traore',
                      value: 7000,
                      children: [],
                    },
                    {
                      name: 'Fatimata Toure',
                      value: 8000,
                      children: [],
                    },
                  ],
                },
              ],
            },
            {
              name: 'Mariam Coulibaly',
              value: 18000,
              children: [
                {
                  name: 'Issa Konate',
                  value: 8000,
                  children: [
                    {
                      name: 'Salimata Cisse',
                      value: 4000,
                      children: [],
                    },
                    {
                      name: 'Moussa Diarra',
                      value: 4000,
                      children: [],
                    },
                  ],
                },
                {
                  name: 'Hawa Coulibaly',
                  value: 10000,
                  children: [
                    {
                      name: 'Souleymane Sow',
                      value: 5000,
                      children: [],
                    },
                    {
                      name: 'Mamadou Traore',
                      value: 5000,
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      salesData2: [
        { date: new Date(2022, 0, 1), sales: 2000 },
        { date: new Date(2022, 1, 1), sales: 2500 },
        { date: new Date(2022, 2, 1), sales: 3000 },
        { date: new Date(2022, 3, 1), sales: 2800 },
        { date: new Date(2022, 4, 1), sales: 3200 },
        { date: new Date(2022, 5, 1), sales: 3800 },
        { date: new Date(2022, 6, 1), sales: 4000 },
        { date: new Date(2022, 7, 1), sales: 3500 },
        { date: new Date(2022, 8, 1), sales: 3200 },
        { date: new Date(2022, 9, 1), sales: 3800 },
        { date: new Date(2022, 10, 1), sales: 4200 },
        { date: new Date(2022, 11, 1), sales: 4500 },
      ],
      stockData: [
        { category: 'Eau minerale', duration: 20 },
        { category: 'Jus', duration: 30 },
        { category: 'Boissons gazeuses', duration: 40 },
      ],
      salesData3: [
        {
          month: 'Janvier', 'Mariam Kabore': 2000, 'Ousmane Kone': 2500, 'Salifou Ouattara': 1800,
        },
        {
          month: 'Février', 'Mariam Kabore': 2200, 'Ousmane Kone': 2600, 'Salifou Ouattara': 1900,
        },
        {
          month: 'Mars', 'Mariam Kabore': 2300, 'Ousmane Kone': 2800, 'Salifou Ouattara': 2000,
        },
        {
          month: 'Avril', 'Mariam Kabore': 2100, 'Ousmane Kone': 2900, 'Salifou Ouattara': 2100,
        },
        {
          month: 'Mai', 'Mariam Kabore': 2400, 'Ousmane Kone': 2700, 'Salifou Ouattara': 2200,
        },
        {
          month: 'Juin', 'Mariam Kabore': 2700, 'Ousmane Kone': 3000, 'Salifou Ouattara': 2300,
        },
        {
          month: 'Juillet', 'Mariam Kabore': 2800, 'Ousmane Kone': 3200, 'Salifou Ouattara': 2400,
        },
        {
          month: 'Août', 'Mariam Kabore': 2900, 'Ousmane Kone': 3300, 'Salifou Ouattara': 2500,
        },
        {
          month: 'Septembre', 'Mariam Kabore': 2700, 'Ousmane Kone': 3100, 'Salifou Ouattara': 2600,
        },
        {
          month: 'Octobre', 'Mariam Kabore': 2800, 'Ousmane Kone': 3000, 'Salifou Ouattara': 2700,
        },
        {
          month: 'Novembre', 'Mariam Kabore': 3000, 'Ousmane Kone': 3200, 'Salifou Ouattara': 2800,
        },
        {
          month: 'Décembre', 'Mariam Kabore': 3200, 'Ousmane Kone': 3400, 'Salifou Ouattara': 2900,
        },
      ],
      salesSeries: [
        { valueField: 'Mariam Kabore', seriesName: 'Mariam Kabore', color: '#67b7dc' },
        { valueField: 'Ousmane Kone', seriesName: 'Ousmane Kone', color: '#fdd400' },
        { valueField: 'Salifou Ouattara', seriesName: 'Salifou Ouattara', color: '#84b761' },
      ],
    }
  },
}
</script>

<style scoped>

</style>
