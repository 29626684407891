import { render, staticRenderFns } from "./VueGoodTableVue.vue?vue&type=template&id=6a08ca78&scoped=true&"
import script from "./VueGoodTableVue.vue?vue&type=script&lang=js&"
export * from "./VueGoodTableVue.vue?vue&type=script&lang=js&"
import style0 from "./VueGoodTableVue.vue?vue&type=style&index=0&id=6a08ca78&lang=scss&scoped=true&"
import style1 from "./VueGoodTableVue.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a08ca78",
  null
  
)

export default component.exports