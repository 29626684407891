<template>
  <div
    ref="chartdiv"
    style="width: 100%; height: 500px;"
  />
</template>

<script>

import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themesAnimated)

export default {
  name: 'LineWithChangingColor',
  props: {
    data: {
      type: Array,
      required: true,
    },
    valueField: {
      type: String,
      required: true,
    },
    dateField: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  mounted() {
    this.chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)
    this.chart.exporting.menu = new am4core.ExportMenu()
    this.chart.data = this.data

    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis())
    dateAxis.renderer.grid.template.location = 0
    dateAxis.dateFormats.setKey('day', 'MMM dd')
    dateAxis.tooltipDateFormat = 'YYYY-MM-DD'
    dateAxis.title.text = 'Date'
    dateAxis.renderer.grid.template.location = 0
    dateAxis.renderer.ticks.template.disabled = true
    dateAxis.renderer.line.opacity = 0
    dateAxis.renderer.grid.template.disabled = true
    dateAxis.renderer.minGridDistance = 40
    dateAxis.dataFields.category = 'month'
    dateAxis.startLocation = 0.4
    dateAxis.endLocation = 0.6

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.title.text = 'Valeur'
    valueAxis.tooltip.disabled = true
    valueAxis.renderer.line.opacity = 0
    valueAxis.renderer.ticks.template.disabled = true
    valueAxis.min = 0

    const series = this.chart.series.push(new am4charts.LineSeries())
    series.dataFields.dateX = this.dateField
    series.dataFields.valueY = this.valueField
    series.strokeWidth = 2
    series.minBulletDistance = 10
    series.tooltipText = '{valueY}'

    const bullet = series.bullets.push(new am4charts.Bullet())
    bullet.adapter.add('fill', (fill, target) => {
      const value = target.dataItem.valueY
      if (value > 100) {
        return am4core.color('#FF0000')
      }
      return am4core.color('#00FF00')
    })

    const hoverState = bullet.states.create('hover')
    hoverState.properties.scale = 1.2

    this.chart.cursor = new am4charts.XYCursor()

    this.chart.scrollbarX = new am4charts.XYChartScrollbar()
    this.chart.scrollbarX.series.push(series)
    this.chart.scrollbarX.parent = this.chart.bottomAxesContainer

    this.chart.legend = new am4charts.Legend()
    this.chart.legend.useDefaultMarker = true

    this.chart.events.on('ready', () => {
      this.chart.animate(
        [
          { property: 'scrollbarX.thumb.background.fill', to: '#0000FF' },
          { property: 'scrollbarX.thumb.background.fill', to: '#00FF00' },
        ],
        2000,
        am4core.ease.sinInOut,
      )
    })
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<style scoped>

</style>
