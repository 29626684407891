<template>
  <div>
    <div
      ref="chartdiv"
      style="width: 100%; height: 500px;"
    />
  </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themesAnimated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themesAnimated)

export default {
  name: 'RotationChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  mounted() {
    const chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart)

    chart.data = this.data

    chart.colors.step = 2

    chart.legend = new am4charts.Legend()
    chart.legend.position = 'top'
    chart.legend.paddingBottom = 20
    chart.legend.labels.template.maxWidth = 95

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'category'
    categoryAxis.renderer.grid.template.location = 0

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.title.text = 'Durée de rotation moyenne (jours)'
    valueAxis.min = 0

    const createSeries = (field, name, stacked) => {
      const series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = field
      series.dataFields.categoryX = 'category'
      series.name = name
      series.columns.template.tooltipText = '{name}: [bold]{valueY}[/]'
      series.stacked = stacked
      series.columns.template.width = am4core.percent(60)
      return series
    }

    createSeries('duration', 'Durée', false)

    chart.cursor = new am4charts.XYCursor()
    chart.exporting.menu = new am4core.ExportMenu()
    this.chart = chart
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>
