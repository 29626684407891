<template>
  <div>
<!--    <b-overlay :show="isLoading">-->
      <b-card :title="title">
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Recherche
              </label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Recherche"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
          <!-- primary -->
          <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Exporter"
            variant="primary"
            class="ml-2 btn_export"
          >
            <b-dropdown-item @click="exportCSV">
              CSV
            </b-dropdown-item>
            <b-dropdown-item @click="exportExcel">
              MS-Excel
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          theme="bootstrap"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  Affichage de 1 à
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> sur {{ props.total }} élements </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>

<!--    </b-overlay>-->

  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import {
  BCard, BDropdown, BOverlay, BFormInput, BDropdownItem, BFormGroup, BFormSelect, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'VueGoodTableVue',
  components: {
    VueGoodTable, BOverlay, BCard, BDropdown, BFormInput, BDropdownItem, BFormGroup, BFormSelect, BPagination,
  },
  directives: {
    Ripple,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      pageLength: 10,
      searchTerm: '',
    }
  },
  computed: {
    isLoading() {
      return this.rows.length === 0
    },
  },
  methods: {
    exportExcel() {
      // const header = this.columns.map(column => column.label)
      // const data = this.rows.map(row => this.columns.map(column => row[column.field]))
      // const excelData = [header, ...data]
      // export_json_to_excel(excelData, this.filename)
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.rows.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
